import { REGISTER_ROUTE } from 'layouts/Layout';
import { addInvisibleCtaButton, triggerHubspotCTA } from './hubspotCTA';

let hubspotInjected = false;
export let hubspotLoaded = false;
export let hubspotCtaQueue: string[] = [];
export const initHubspot = () => {
  addInvisibleCtaButton('launches', [
    'hs-cta-trigger-button',
    process.env.REACT_APP_ENVIRONMENT === 'prod'
      ? 'hs-cta-trigger-button-186057989701'
      : 'hs-cta-trigger-button-213075107016'
  ]);
  addInvisibleCtaButton('awards', [
    'hs-cta-trigger-button',
    process.env.REACT_APP_ENVIRONMENT === 'prod'
      ? 'hs-cta-trigger-button-186057989688'
      : 'hs-cta-trigger-button-212756064484'
  ]);

  if (
    // For testing purposes, we don't want to inject the Hubspot script sometimes
    window.location.search.includes('no-hubspot')
  ) {
    return;
  } else if (
    // We need to inject the script on the register page directly to ensure the form is sent to Hubspot
    window.location.pathname.includes(REGISTER_ROUTE) ||
    // we want to trigger the awards CTA when 2025 is in the url, as incosmetics will have the link already mapped on their side
    window.location.search.includes('2025')
  ) {
    injectHubspotScript();
  } else {
    // On all other pages the script gets injected when the user scrolls or after 5 seconds
    // We assume that the Google crawler does not scroll.
    window.addEventListener('scroll', () => injectHubspotScript(), {
      once: true
    });
    setTimeout(injectHubspotScript, 5000);
  }
};

const injectHubspotScript = () => {
  if (!hubspotInjected) {
    const hubspotUrl = process.env.REACT_APP_HUBSPOT_URL;
    if (hubspotUrl) {
      const script = document.createElement('script');
      script.src = hubspotUrl;
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';
      script.type = 'text/javascript';
      script.onload = () => {
        setTimeout(() => {
          hubspotLoaded = true;
          hubspotCtaQueue.forEach(triggerHubspotCTA);
          hubspotCtaQueue = [];
        }, 2000);
      };
      document.body.appendChild(script);
    }
    hubspotInjected = true;
  }
};
