import { CovaloPermissionKey } from 'helpers/Roles';
import { Navigate, useParams } from 'react-router-dom';
import { checkPrivateRoutePermissions } from './checkPrivateRoutePermissions';
import { useUserState } from 'context/User';
import { useAuth } from 'react-oidc-context';
import { UserStateContextType } from 'components/constants/Constants';
import { BrandPortalPermissionKey } from 'portals/types';
import MainLayout from 'layouts/MainLayout';

type PrivateRouteProps = {
  component: JSX.Element;
  allowedUsers?: string[];
  excludeRoles?: string[];
  hasWhiteBackground?: boolean;
  preventDoubleScrollbars?: boolean;
  noVerticalPadding?: boolean;
  permissions?: CovaloPermissionKey[];
  brandPortalPermissions?: BrandPortalPermissionKey[];
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  allowedUsers,
  excludeRoles,
  permissions,
  brandPortalPermissions,
  ...rest
}) => {
  const userState = useUserState();

  const auth = useAuth();

  const { event: eventKey } = useParams();

  // Redirect to search page if user is signing out, because he won't have access to this private route anymore
  // It's only needed for signout silent which we don't use at the moment, but we keep this check here to not
  // break things in the future
  if (auth.activeNavigator === 'signoutSilent')
    return <Navigate to="/ingredients/search" />;

  switch (userState.type) {
    case UserStateContextType.LOADING:
      return null;
    case UserStateContextType.NOT_AUTHENTICATED:
      return <Navigate to="/ingredients/search" />;
    case UserStateContextType.AUTHENTICATED: {
      const isAllowed = checkPrivateRoutePermissions(
        userState.user,
        allowedUsers,
        excludeRoles,
        permissions,
        brandPortalPermissions,
        eventKey
      );
      return isAllowed ? (
        <MainLayout component={component} {...rest} />
      ) : (
        <Navigate to="/" />
      );
    }
  }
};

export default PrivateRoute;
