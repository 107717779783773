import {
  getNotifications,
  readAllNotifications
} from 'api/services/NotificationsService';
import {
  NOTIFICATIONS_REFRESH_INTERVAL,
  NOTIFICATIONS_REFRESH_INTERVAL_ON_PAGE,
  UserStateContextType
} from 'components/constants/Constants';
import { removeSomeNotifications } from 'components/pages/Notifications/Notifications';
import { useInterval } from 'hooks/useInterval';
import { createContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Notification } from 'components/notification/types';
import { useUserState } from './User';

export interface Message {
  created_at: string;
  created_by: {
    firstname: string;
    lastname: string;
  };
  key: string;
  message: string;
  message_status: {
    read_: boolean;
  };
}
interface NotificationContext {
  notifications: Notification[];
  setMessages: (messages: Message[]) => void;
  refreshInterval: number;
  refreshIntervalOnPage: number;
  messages: Message[];
  markAsRead: () => void;
  grabNotifications: () => void;
}

const NotificationsContext = createContext<NotificationContext>({
  refreshInterval: NOTIFICATIONS_REFRESH_INTERVAL, // slower update of the overall notifications of the website
  refreshIntervalOnPage: NOTIFICATIONS_REFRESH_INTERVAL_ON_PAGE, // for when the user is on a page where is nicer to have faster refreshed information
  messages: [],
  notifications: [],
  setMessages: () => {},
  markAsRead: () => {},
  grabNotifications: () => {}
});

//TODO: move the state here like in this example https://kattya.dev/articles/2021-04-17-fixing-re-renders-when-using-context-in-react/
// and on all other contexts to be cleaner
const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const userState = useUserState();
  const setMessages = (messages: Message[]) => {
    setNotificationsContext({ ...notificationsContext, messages: messages });
  };
  const markAsReadAll = () => {
    readAllNotifications(
      () => fetchNotifications(),
      () => toast.error('Could not mark all notifications as read')
    );
  };
  const fetchNotifications = () => {
    getNotifications(
      '?sort=triggered_at,desc&size=300',
      (response: { data: { content: Notification[] } }) => {
        if (response.data.content.length > 0) {
          const contentFiltered = removeSomeNotifications(
            response.data.content
          );
          const hasNewNotifications =
            JSON.stringify(notificationsContext.notifications) !==
            JSON.stringify(contentFiltered);
          if (hasNewNotifications) {
            const updated = {
              ...notificationsContext,
              notifications: contentFiltered
            };
            setNotificationsContext({ ...updated });
          }
        }
      }
    );
  };

  const [notificationsContext, setNotificationsContext] =
    useState<NotificationContext>({
      refreshInterval: NOTIFICATIONS_REFRESH_INTERVAL,
      refreshIntervalOnPage: NOTIFICATIONS_REFRESH_INTERVAL_ON_PAGE,
      messages: [],
      notifications: [],
      setMessages: setMessages,
      markAsRead: markAsReadAll,
      grabNotifications: fetchNotifications
    });

  useInterval(() => {
    if (userState.type !== UserStateContextType.AUTHENTICATED) return;
    // TODO: make here the api calls to simulate websockets and add or not dot to the icon depending if there is new notifications
    fetchNotifications();
  }, notificationsContext.refreshInterval);

  return (
    <NotificationsContext.Provider value={notificationsContext}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
