import { toast } from 'react-toastify';
import { axiosWithAuth } from './axios/globalAxios';

/**
 * @deprecated Use the TypeScript file instead, preferrably in combination with `useApi`
 */
export const request = (
  options,
  success,
  handleError,
  toastOptions,
  customErrorMessage = false
) => {
  let toastId;
  let promise = () =>
    new Promise((resolve, reject) =>
      axiosWithAuth.request(options).then(
        function (response) {
          success(response);
          resolve();
        },
        function (error) {
          if (toastOptions && !toastOptions?.error && !customErrorMessage) {
            toast.dismiss(toastId);
            toast.error(
              error?.response?.data?.detail ??
                error?.message ??
                'Something went wrong'
            );
          }
          if (handleError) {
            handleError(error);
          }

          reject(error);
        }
      )
    );

  return (
    toastOptions ? toast.promise(promise, toastOptions) : promise()
  ).catch(() => {
    // We don't want to have unhandled promise rejection errors in the console.
    // It's possible to handle the error in a user friendly way via handleError.
  });
};
