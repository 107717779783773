import {
  CovaloPermissionLevel,
  PermissionKey
} from 'components/common/User/Permissions';
import { hasAccess, hasPermission, isSupport } from 'helpers/Roles';
import { BrandPortalPermissionKey, PortalPermissionLevel } from 'portals/types';
import { LoggedInUser } from 'types/User';

export const checkPrivateRoutePermissions = (
  user: LoggedInUser,
  allowedUsers?: string[],
  excludeRoles?: string[],
  permissions?: PermissionKey[],
  brandPortalPermission?: BrandPortalPermissionKey[],
  eventKey?: string
) => {
  const event = user?.brandPortalEvent?.find(event => event.key === eventKey);
  if (isSupport(user)) return true;
  if (!user.roles) return false;
  if (brandPortalPermission && brandPortalPermission.length > 0) {
    return brandPortalPermission.some(permission => {
      if (permission === BrandPortalPermissionKey.SEARCH) {
        return event?.permissions?.[permission] === PortalPermissionLevel.VIEW;
      } else {
        return (
          event?.permissions?.[permission] === PortalPermissionLevel.MANAGE
        );
      }
    });
  }
  if (permissions && permissions.length > 0) {
    return (
      hasPermission(
        permissions,
        [PortalPermissionLevel.MANAGE, CovaloPermissionLevel.VIEW],
        user
      ) && hasAccess(allowedUsers, excludeRoles, user)
    );
  } else {
    return hasAccess(allowedUsers, excludeRoles, user);
  }
};
