import PropTypes from 'prop-types';

const CompanyDetails = {
  headquarters: PropTypes.string
};
export const Company = {
  key: PropTypes.string,
  name: PropTypes.string,
  details: CompanyDetails,
  sustainable_development_goal: PropTypes.arrayOf(PropTypes.string)
};
