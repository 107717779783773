import { AutocompleteRenderInputParams, TextField } from '@mui/material';
import { removeDuplicatesByKey } from 'components/common/Requests/helpers/helpers';
import { useEffect, useState } from 'react';
import { Option, OptionInternal } from './types';
import { toast } from 'react-toastify';
import { isBottomReached } from '../isBottomReached';

export const useAsyncSelect = (
  getOption: (page: number, search: string) => Promise<any>,
  placeholder: string,
  label: string | undefined,
  margin?: 'dense' | 'normal' | 'none'
) => {
  const [options, setOptions] = useState<OptionInternal[]>(() => []);
  const [page, setPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [searchState, setSearchState] = useState('');

  const handleInputChange = (input: string) => {
    setSearchState(input);
  };
  const onMenuScrollDown = () => {
    if (hasMorePages)
      getOption(page + 1, searchState)
        .then((response: any) => {
          if (response.length === 0) {
            setHasMorePages(false);
            return;
          }
          const newOptions = [...options, ...response];
          setOptions(removeDuplicatesByKey(newOptions, 'label'));

          setPage(curr => curr + 1);
        })
        .catch(error => {
          toast.error(error);
        });
  };

  useEffect(() => {
    getOption(0, searchState)
      .then((response: any) => {
        if (response.length === 0) {
          setHasMorePages(false);
          return;
        }
        setOptions(removeDuplicatesByKey(response, 'label'));
        setPage(0);
        setHasMorePages(true);
      })
      .catch(error => {
        toast.error(error);
      });
  }, [getOption, searchState]);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      margin={margin ?? 'normal'}
      placeholder={placeholder}
      label={label}
    />
  );
  const onListBoxScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    if (isBottomReached(event.currentTarget)) {
      onMenuScrollDown();
    }
  };
  /**
   * And we hide the values again, because they would confuse the user.
   */
  const filterOutValuesAgain = (options: OptionInternal[]) =>
    options.filter(o => !o.isValue);

  const defaultProps = {
    fullWidth: true,
    renderInput: renderInput,
    disableCloseOnSelect: true,
    getOptionLabel: (option: { label: string }) => option.label ?? '',
    isOptionEqualToValue: (
      option: OptionInternal | Option,
      value: OptionInternal | Option
    ) => option.value === value.value,
    onInputChange: (_: any, newInputValue: string) =>
      handleInputChange(newInputValue),
    filterOptions: (options: OptionInternal[] | Option[]) =>
      filterOutValuesAgain(options)
  };
  return {
    options,
    defaultProps,
    onListBoxScroll
  };
};
