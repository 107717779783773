import { hubspotCtaQueue, hubspotLoaded } from './initHubspot';

export const triggerHubspotCTA = (id: string) => {
  if (hubspotLoaded) {
    const button = document.getElementById(id);

    if (button) {
      const buttonRect = button.getBoundingClientRect();
      const mouseUpEvent = new MouseEvent('mouseup', {
        bubbles: true,
        cancelable: true,
        view: window,
        clientX: buttonRect.left + buttonRect.width / 2, // Center X position of the button
        clientY: buttonRect.top + buttonRect.height / 2, // Center Y position of the button
        button: 0 // Assuming the primary mouse button is used
      });
      button.dispatchEvent(mouseUpEvent);
    }
  } else {
    hubspotCtaQueue.push(id);
  }
};

export const addInvisibleCtaButton = (id: string, classes: string[]) => {
  const button = document.createElement('button');

  button.textContent = 'Hubspot CTA';
  button.classList.add(...classes);
  button.id = id;

  getOrCreateButtonContainer().appendChild(button);
};

const getOrCreateButtonContainer = () => {
  const id = 'hubspot-cta-button-container';
  let container = document.getElementById(id);

  if (!container) {
    container = document.createElement('div');
    container.id = id;
    container.style.opacity = '0';
    container.style.position = 'absolute';
    container.style.bottom = '0';
    document.body.appendChild(container);
  }

  return container;
};
