import PiwikReactRouter from 'piwik-react-router';

type PiwikWrapper = {
  push: (args: string[]) => void;
};

const createPiwik = (): PiwikWrapper => ({
  push: process.env.REACT_APP_MATOMO_URL
    ? PiwikReactRouter({
        url: process.env.REACT_APP_MATOMO_URL,
        siteId: '1',
        trackErrors: true,
        enableLinkTracking: true
      }).push
    : (args: string[]) => {
        // eslint-disable-next-line no-console
        console.warn(
          'Matomo is not enabled on this environment, dropping the event...',
          args
        );
      }
});

const piwik = createPiwik();

export default piwik;

// helper function
export const trackSearchEvent = (
  action: string | undefined,
  message: string
) => {
  if (!action) {
    return;
  }
  piwik.push(['trackEvent', 'Search', action, message]);
  piwik.push(['trackGoal', '1']);
};
