import { getUser } from 'auth/AuthenticationProvider';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ToastPromiseParams, toast } from 'react-toastify';
import { axiosWithAuth } from './axios/globalAxios';

export type SuccessHandler<T = any, D = any> = (
  response: AxiosResponse<T, D>
) => void;
export type ErrorHandler = (error: any) => void;
export type Options = AxiosRequestConfig;

export const request = <T, D>(
  options: Options,
  success: SuccessHandler<T, D>,
  handleError?: ErrorHandler,
  /**
   * When this object is only partly defined, there might be strange behaviour.
   * For example when error is not defined, the default error message will show up, but
   * be overridden after a second by an empty error message and therefore disappear.
   */
  toastPromiseParams?: ToastPromiseParams,
  customErrorMessage = false
) => {
  const promise = () =>
    new Promise<void>((resolve, reject) =>
      axiosWithAuth.request(options).then(
        function (response) {
          success(response);
          resolve();
        },
        function (error: AxiosError) {
          if (
            toastPromiseParams &&
            !toastPromiseParams?.error &&
            !customErrorMessage
          ) {
            toast.error(
              //@ts-ignore we have this structure in some responses
              error?.response?.data?.detail ??
                error?.message ??
                'Something went wrong'
            );
          }
          if (handleError) {
            handleError(error);
          }

          reject(error);
        }
      )
    );

  return (
    toastPromiseParams ? toast.promise(promise, toastPromiseParams) : promise()
  ).catch(() => {
    // We don't want to have unhandled promise rejection errors in the console.
    // It's possible to handle the error in a user friendly way via handleError.
  });
};

export const requestRedirectDownload = (
  options: { url: string; headers: any },
  handleError: ErrorHandler
) => {
  if (!options['headers']) {
    options['headers'] = {};
  }
  options['headers']['Authorization'] = `Bearer ${getUser()?.access_token}`;
  const headers = options.headers;
  fetch(options.url, { headers })
    .then(res => window.open(res.url, '_blank'))
    .catch(function (error) {
      if (handleError) {
        handleError(error);
      }
    });
};
