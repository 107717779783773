import {
  ContactRequestContext,
  LastRequest,
  RequestContext
} from 'types/ContactRequests';
import { FilesTypes } from '../../../../api/types/ReferenceData';
import { DocumentTypes } from '../RequestDocumentDialog';

export const formatProducts = (
  context: RequestContext,
  fullProducts: any[]
) => {
  switch (context.type) {
    case ContactRequestContext.COVALO:
    case ContactRequestContext.BRAND_PORTAL:
      return fullProducts.map(product => ({
        company: product.company.key,
        product: product.key,
        segment: product.segment
      }));

    case ContactRequestContext.SUPPLIER_PORTAL:
      return fullProducts;

    case ContactRequestContext.PROJECT:
      return fullProducts.map(product => product.key);

    default:
      break;
  }
};

export const documentTypesToObjectArr = (types: string[]) => {
  const typesArr: DocumentTypes[] = [];
  types.forEach(type => {
    const matchingType = FilesTypes.find(t => t.value === type);
    if (matchingType) {
      typesArr.push(matchingType);
    }
  });
  return typesArr;
};

export const removeDuplicateDocumentTypes = (types: DocumentTypes[]) => {
  const uniqueTypes = [...new Set(types)];
  return uniqueTypes;
};

export function removeDuplicatesByKey(arr: any[], key: string) {
  return arr.filter((obj, index, array) => {
    // Keep only the first occurrence of each unique label
    return array.map(mapObj => mapObj[key]).indexOf(obj[key]) === index;
  });
}

interface ValidPrefillDataProps {
  data: LastRequest | undefined;
  children: any;
}
export const ValidPrefillData = ({ data, children }: ValidPrefillDataProps) => {
  if (!data) {
    return null;
  }
  return <>{children} </>;
};
