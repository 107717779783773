import { CovaloPermissionKey as CovaloPermissionKey } from 'helpers/Roles';
import {
  BrandPortalPermissionKey,
  BrandPortalPermissionLevel,
  PortalPermissionLevel,
  PortalPermissions,
  SupplierPortalPermissionKey
} from 'portals/types';

export enum CovaloPermissionLevel {
  VIEW = 'view'
}

export type CovaloPermissions = {
  [key in CovaloPermissionKey]?: CovaloPermissionLevel;
};

export type Permissions = CovaloPermissions & PortalPermissions;

export type PermissionKey =
  | CovaloPermissionKey
  | SupplierPortalPermissionKey
  | BrandPortalPermissionKey;

export type PermissionLevel = CovaloPermissionLevel | PortalPermissionLevel;

export const AVAILABLE_PORTAL_PERMISSIONS: Record<
  SupplierPortalPermissionKey,
  PortalPermissionLevel[]
> &
  Record<BrandPortalPermissionKey, BrandPortalPermissionLevel[]> = {
  [SupplierPortalPermissionKey.USER_MANAGEMENT]: [
    PortalPermissionLevel.MANAGE,
    PortalPermissionLevel.VIEW
  ],
  [SupplierPortalPermissionKey.PRODUCT_MANAGEMENT]: [
    PortalPermissionLevel.MANAGE,
    PortalPermissionLevel.VIEW
  ],
  [SupplierPortalPermissionKey.CONTENT_MANAGEMENT]: [
    PortalPermissionLevel.MANAGE
  ],
  [BrandPortalPermissionKey.USER_MANAGEMENT]: [
    BrandPortalPermissionLevel.MANAGE
  ],
  [BrandPortalPermissionKey.PRODUCT_MANAGEMENT]: [
    BrandPortalPermissionLevel.MANAGE
  ],
  [BrandPortalPermissionKey.COMPANY_MANAGEMENT]: [
    BrandPortalPermissionLevel.MANAGE
  ],
  [BrandPortalPermissionKey.DOCUMENT_MANAGEMENT]: [
    BrandPortalPermissionLevel.MANAGE
  ],
  [BrandPortalPermissionKey.SEARCH]: [BrandPortalPermissionLevel.MANAGE]
};
