import { baseApi } from 'components/constants/Constants';
import { useAxiosWithAuth } from './useAxios';
import { Company } from 'types/Companies';

export const useGetCompanyOptions = (page: number, search: string) =>
  useAxiosWithAuth<{ content: { name: string; key: string }[] }>(
    {
      url: `${baseApi}/companies`,
      params: {
        page,
        operator: 'OR',
        fields: 'name,key',
        sort: 'name,asc',
        name__icontains: search,
        key__icontains: search
      },
      method: 'GET'
    },
    { manual: true }
  );

/**
 * Gets all Companies which are inviteable.
 * - Returns all companies that can the user has access to.
 * - Special case: If the user is `info@covalo.com`, or belongs to harmona it returns all companies.
 * - We need this special behavior on the invite modal
 */
export const useGetInviteableCompanyOptions = (page: number, search: string) =>
  useAxiosWithAuth<{ content: { name: string; key: string }[] }>(
    {
      url: `${baseApi}/companies/invitable`,
      params: {
        page,
        operator: 'OR',
        fields: 'name,key',
        sort: 'name,asc',
        name__icontains: search,
        key__icontains: search
      },
      method: 'GET'
    },
    { manual: true }
  );

export const useGetCompanyOptionsForEvent = (
  page: number,
  search: string,
  eventKey?: string
) =>
  useAxiosWithAuth<{
    content: { name: string; key: string; eventKey?: string }[];
  }>(
    {
      url: `${baseApi}/events/${eventKey}/companies/available`,
      params: {
        page,
        operator: 'AND',
        sort: 'name,asc',
        name__icontains: search,
        include_disabled: true,
        offering__eq: 'INGREDIENTS',
        type__eq: ['SUPPLIER_OF_RAW_MATERIALS_AND_INGREDIENTS', 'DISTRIBUTOR']
      },
      method: 'GET'
    },
    { manual: true }
  );

export const useUpdateCompanyName = (company: Company) =>
  useAxiosWithAuth(
    {
      url: `${baseApi}/companies/${company.key}/name`,
      method: 'POST'
    },
    { manual: true }
  );
