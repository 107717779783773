const CovaloLogo: React.FC<{
  variant: 'small' | 'legacy';
}> = ({ variant }) =>
  variant === 'small' ? (
    <svg
      width="103"
      height="16"
      viewBox="0 0 103 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4124 3.40356H14.8868H14.3788H12.1293H10.0267H9.53053H8.96343H8.43775L4.95026 9.44388L5.29905 10.0479H7.4016L7.05296 9.44388L9.48895 5.22435H9.53053H11.0779H13.1805H14.2534H14.3612L16.7972 9.44388L14.3612 13.6633H9.48895L9.18794 13.1419H7.08555L8.43775 15.484H15.4124L18.8998 9.44388L15.4124 3.40356Z"
        fill="#FF6B6B"
      />
      <path
        d="M9.26606 12.5963H9.9365H10.462L13.9494 6.55603L13.5481 5.86092H11.4456L11.8468 6.55603L9.41082 10.7756H9.26606H7.82169H5.7193H4.63179H4.53854L2.10255 6.55603L4.53854 2.33665H9.41082L9.65923 2.76691H11.7618L10.462 0.515869H3.48734L0 6.55603L3.48734 12.5963H4.01302H4.63179H6.77049H8.87304H9.26606Z"
        fill="#FF6B6B"
      />
      <path
        d="M26.9727 4.43259C27.6277 3.27216 28.5215 2.36417 29.6536 1.70909C30.7857 1.05417 32.0424 0.726318 33.4239 0.726318C35.0475 0.726318 36.4642 1.11825 37.6749 1.90133C38.885 2.68473 39.7682 3.79551 40.3236 5.23367H37.9953C37.582 4.33654 36.9876 3.64593 36.2116 3.16169C35.4352 2.67775 34.506 2.43523 33.4239 2.43523C32.3842 2.43523 31.4514 2.67775 30.6255 3.16169C29.7996 3.64593 29.1516 4.33329 28.6818 5.22297C28.2117 6.11326 27.9768 7.15639 27.9768 8.35251C27.9768 9.53466 28.2117 10.5708 28.6818 11.4606C29.1516 12.3508 29.7996 13.038 30.6255 13.5221C31.4514 14.0063 32.3842 14.2484 33.4239 14.2484C34.506 14.2484 35.4352 14.0099 36.2116 13.5326C36.9876 13.0557 37.582 12.3683 37.9953 11.4713H40.3236C39.7682 12.8954 38.885 13.9956 37.6749 14.7716C36.4642 15.548 35.0475 15.9357 33.4239 15.9357C32.0424 15.9357 30.7857 15.6121 29.6536 14.964C28.5215 14.316 27.6277 13.4152 26.9727 12.2616C26.3175 11.108 25.9901 9.8051 25.9901 8.35251C25.9901 6.89976 26.3175 5.59348 26.9727 4.43259Z"
        fill="#FF6B6B"
      />
      <path
        d="M50.9186 13.7996C51.5307 13.4722 52.0258 12.981 52.4032 12.3256C52.7805 11.6708 52.9694 10.8732 52.9694 9.93336C52.9694 8.99341 52.7841 8.1959 52.414 7.54082C52.0435 6.8859 51.5594 6.39792 50.9613 6.07736C50.3633 5.75696 49.7151 5.59684 49.0175 5.59684C48.3051 5.59684 47.6538 5.75696 47.0629 6.07736C46.4718 6.39792 45.9982 6.8859 45.6423 7.54082C45.2862 8.1959 45.1084 8.99341 45.1084 9.93336C45.1084 10.8874 45.2825 11.6921 45.6318 12.347C45.9806 13.0022 46.4468 13.4899 47.0308 13.8103C47.6147 14.1309 48.2555 14.291 48.9534 14.291C49.651 14.291 50.3062 14.1274 50.9186 13.7996ZM45.9734 15.2522C45.0833 14.7538 44.3854 14.0454 43.88 13.1268C43.3744 12.2083 43.1218 11.1439 43.1218 9.93336C43.1218 8.73693 43.3813 7.67953 43.9014 6.76099C44.4212 5.84245 45.1297 5.13741 46.027 4.64618C46.9241 4.15495 47.9281 3.90918 49.0389 3.90918C50.1497 3.90918 51.1537 4.15495 52.0508 4.64618C52.9479 5.13741 53.6562 5.8392 54.1763 6.75029C54.696 7.66184 54.956 8.72297 54.956 9.93336C54.956 11.1439 54.689 12.2083 54.1549 13.1268C53.6209 14.0454 52.898 14.7538 51.9867 15.2522C51.0752 15.7509 50.0642 16.0001 48.9534 16.0001C47.8567 16.0001 46.8633 15.7509 45.9734 15.2522Z"
        fill="#FF6B6B"
      />
      <path
        d="M62.5177 14.0131L66.1492 4.10132H68.2214L63.6285 15.8076H61.3643L56.7716 4.10132H58.865L62.5177 14.0131Z"
        fill="#FF6B6B"
      />
      <path
        d="M79.4358 7.62603C79.0797 6.97126 78.599 6.46917 77.9941 6.12007C77.3885 5.77127 76.723 5.59672 75.9965 5.59672C75.2702 5.59672 74.6082 5.76754 74.0101 6.10951C73.4119 6.45117 72.9347 6.94985 72.5789 7.60462C72.2226 8.26001 72.0448 9.02897 72.0448 9.91183C72.0448 10.809 72.2226 11.5886 72.5789 12.2508C72.9347 12.9131 73.4119 13.4187 74.0101 13.7674C74.6082 14.1166 75.2702 14.2909 75.9965 14.2909C76.723 14.2909 77.3885 14.1166 77.9941 13.7674C78.599 13.4187 79.0797 12.9131 79.4358 12.2508C79.7916 11.5886 79.9698 10.8162 79.9698 9.93324C79.9698 9.05023 79.7916 8.28127 79.4358 7.62603ZM70.7845 6.76087C71.2684 5.85676 71.9343 5.1556 72.7817 4.65677C73.6288 4.1584 74.5725 3.90906 75.6121 3.90906C76.6375 3.90906 77.5273 4.13016 78.2823 4.57143C79.037 5.01285 79.5995 5.56832 79.9698 6.23752V4.1013H81.9351V15.8075H79.9698V13.6288C79.5854 14.3121 79.0121 14.8783 78.2502 15.3269C77.4882 15.7754 76.6018 15.9999 75.5908 15.9999C74.5511 15.9999 73.6111 15.7433 72.7711 15.2307C71.9306 14.718 71.2684 13.9992 70.7845 13.0732C70.3002 12.1478 70.0582 11.094 70.0582 9.91183C70.0582 8.7154 70.3002 7.66544 70.7845 6.76087Z"
        fill="#FF6B6B"
      />
      <path d="M87.8094 15.8075H85.8656V0H87.8094V15.8075Z" fill="#FF6B6B" />
      <path
        d="M98.8317 13.7996C99.4438 13.4722 99.9389 12.981 100.316 12.3256C100.694 11.6708 100.882 10.8732 100.882 9.93336C100.882 8.99341 100.697 8.1959 100.327 7.54082C99.9566 6.8859 99.4725 6.39792 98.8744 6.07736C98.2764 5.75696 97.6282 5.59684 96.9306 5.59684C96.2182 5.59684 95.5669 5.75696 94.976 6.07736C94.3849 6.39792 93.9113 6.8859 93.5554 7.54082C93.1993 8.1959 93.0215 8.99341 93.0215 9.93336C93.0215 10.8874 93.1956 11.6921 93.5449 12.347C93.8936 13.0022 94.3599 13.4899 94.9439 13.8103C95.5278 14.1309 96.1686 14.291 96.8665 14.291C97.5641 14.291 98.2193 14.1274 98.8317 13.7996ZM93.8865 15.2522C92.9964 14.7538 92.2985 14.0454 91.7931 13.1268C91.2875 12.2083 91.0349 11.1439 91.0349 9.93336C91.0349 8.73693 91.2944 7.67953 91.8145 6.76099C92.3343 5.84245 93.0428 5.13741 93.94 4.64618C94.8372 4.15495 95.8412 3.90918 96.952 3.90918C98.0628 3.90918 99.0668 4.15495 99.9639 4.64618C100.861 5.13741 101.569 5.8392 102.089 6.75029C102.609 7.66184 102.869 8.72297 102.869 9.93336C102.869 11.1439 102.602 12.2083 102.068 13.1268C101.534 14.0454 100.811 14.7538 99.8998 15.2522C98.9883 15.7509 97.9773 16.0001 96.8665 16.0001C95.7697 16.0001 94.7763 15.7509 93.8865 15.2522Z"
        fill="#FF6B6B"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={135}
      height={21}
      viewBox="0 0 134 21"
    >
      <defs>
        <clipPath id="a">
          <path d="M6 4h19v16.918H6Zm0 0" />
        </clipPath>
        <clipPath id="b">
          <path d="M33 0h20v20.918H33Zm0 0" />
        </clipPath>
        <clipPath id="c">
          <path d="M56 5h16v15.918H56Zm0 0" />
        </clipPath>
        <clipPath id="d">
          <path d="M73 5h16v15.918H73Zm0 0" />
        </clipPath>
        <clipPath id="e">
          <path d="M91 5h16v15.918H91Zm0 0" />
        </clipPath>
        <clipPath id="f">
          <path d="M111 0h4v20.918h-4Zm0 0" />
        </clipPath>
        <clipPath id="g">
          <path d="M118 5h15.965v15.918H118Zm0 0" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          d="M20.07 4.45h-9.082l-4.543 7.898.457.789h2.739l-.457-.79 3.171-5.515h6.348l3.172 5.516-3.172 5.515h-6.348l-.39-.683H9.227l1.761 3.062h9.082l4.543-7.894Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#ff6b6b',
            fillOpacity: 1
          }}
        />
      </g>
      <path
        d="M12.066 16.469h1.559l4.543-7.899-.523-.906h-2.739l.524.906-3.176 5.516H5.91L2.738 8.57 5.91 3.055h6.344l.324.562h2.738L13.625.676H4.543L0 8.57l4.543 7.899Zm0 0"
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#ff6b6b',
          fillOpacity: 1
        }}
      />
      <g clipPath="url(#b)">
        <path
          d="M35.125 5.793c.855-1.516 2.02-2.703 3.492-3.559C40.094 1.38 41.727.95 43.527.95c2.114 0 3.961.512 5.535 1.535 1.579 1.028 2.727 2.477 3.45 4.36H49.48c-.539-1.176-1.312-2.078-2.324-2.711-1.008-.633-2.218-.95-3.629-.95-1.355 0-2.57.317-3.644.95-1.074.633-1.918 1.531-2.531 2.695-.614 1.164-.918 2.527-.918 4.09 0 1.547.304 2.902.918 4.066.613 1.164 1.457 2.063 2.53 2.696 1.075.633 2.29.949 3.645.949 1.41 0 2.621-.313 3.63-.938 1.011-.62 1.784-1.523 2.323-2.695h3.032c-.723 1.863-1.871 3.3-3.45 4.316-1.574 1.016-3.421 1.52-5.535 1.52-1.8 0-3.433-.422-4.91-1.27a9.274 9.274 0 0 1-3.492-3.53c-.852-1.509-1.277-3.212-1.277-5.114 0-1.898.425-3.605 1.277-5.125Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#ff6b6b',
            fillOpacity: 1
          }}
        />
      </g>
      <g clipPath="url(#c)">
        <path
          d="M66.313 18.04c.796-.427 1.44-1.067 1.93-1.927.491-.855.737-1.898.737-3.125 0-1.23-.242-2.273-.722-3.129-.48-.855-1.113-1.496-1.89-1.914a5.285 5.285 0 0 0-2.532-.629c-.93 0-1.777.211-2.547.63-.77.417-1.387 1.058-1.848 1.913-.464.856-.695 1.899-.695 3.13 0 1.245.227 2.296.68 3.152.453.859 1.062 1.496 1.82 1.914a5.125 5.125 0 0 0 2.504.629c.91 0 1.762-.215 2.563-.645ZM59.87 19.94a6.923 6.923 0 0 1-2.726-2.78c-.66-1.2-.989-2.59-.989-4.173 0-1.566.34-2.949 1.016-4.148.676-1.203 1.601-2.125 2.77-2.766 1.167-.64 2.476-.965 3.921-.965 1.446 0 2.754.325 3.922.965 1.168.64 2.09 1.559 2.77 2.75.675 1.192 1.015 2.578 1.015 4.164 0 1.582-.347 2.973-1.047 4.172a7.253 7.253 0 0 1-2.82 2.781c-1.187.653-2.504.977-3.953.977-1.426 0-2.719-.324-3.879-.977Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#ff6b6b',
            fillOpacity: 1
          }}
        />
      </g>
      <g clipPath="url(#d)">
        <path
          d="m81.418 18.32 4.727-12.957h2.699l-5.98 15.305h-2.95l-5.98-15.305h2.726Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#ff6b6b',
            fillOpacity: 1
          }}
        />
      </g>
      <g clipPath="url(#e)">
        <path
          d="M103.45 9.969c-.466-.856-1.09-1.512-1.88-1.969a5.135 5.135 0 0 0-2.601-.684c-.946 0-1.809.223-2.586.672-.778.446-1.403 1.098-1.863 1.953-.465.86-.696 1.864-.696 3.016 0 1.176.23 2.195.696 3.059.46.867 1.085 1.527 1.863 1.984.777.457 1.64.684 2.586.684.945 0 1.812-.227 2.601-.684.79-.457 1.414-1.117 1.88-1.984.46-.864.695-1.875.695-3.028 0-1.156-.235-2.16-.696-3.02Zm-11.266-1.13c.629-1.183 1.496-2.097 2.597-2.75 1.106-.652 2.332-.98 3.688-.98 1.336 0 2.496.29 3.476.868.985.578 1.715 1.304 2.2 2.18V5.362h2.558v15.305h-2.558v-2.852c-.5.895-1.247 1.637-2.239 2.223-.992.586-2.148.879-3.465.879-1.355 0-2.578-.336-3.671-1.008-1.094-.668-1.957-1.61-2.586-2.82-.633-1.207-.95-2.586-.95-4.133 0-1.562.317-2.934.95-4.117Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#ff6b6b',
            fillOpacity: 1
          }}
        />
      </g>
      <g clipPath="url(#f)">
        <path
          d="M114.352 20.668h-2.532V0h2.532Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#ff6b6b',
            fillOpacity: 1
          }}
        />
      </g>
      <g clipPath="url(#g)">
        <path
          d="M128.707 18.04c.797-.427 1.441-1.067 1.934-1.927.492-.855.738-1.898.738-3.125 0-1.23-.242-2.273-.723-3.129-.484-.855-1.113-1.496-1.894-1.914a5.263 5.263 0 0 0-2.532-.629c-.925 0-1.773.211-2.543.63-.769.417-1.386 1.058-1.851 1.913-.465.856-.695 1.899-.695 3.13 0 1.245.226 2.296.683 3.152.453.859 1.059 1.496 1.82 1.914a5.114 5.114 0 0 0 2.504.629c.907 0 1.762-.215 2.559-.645Zm-6.441 1.901a6.938 6.938 0 0 1-2.723-2.78c-.66-1.2-.988-2.59-.988-4.173 0-1.566.336-2.949 1.015-4.148.676-1.203 1.598-2.125 2.766-2.766 1.168-.64 2.477-.965 3.922-.965 1.449 0 2.754.325 3.926.965 1.168.64 2.09 1.559 2.765 2.75.676 1.192 1.016 2.578 1.016 4.164 0 1.582-.348 2.973-1.043 4.172a7.237 7.237 0 0 1-2.824 2.781c-1.188.653-2.504.977-3.95.977-1.43 0-2.722-.324-3.882-.977Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#ff6b6b',
            fillOpacity: 1
          }}
        />
      </g>
    </svg>
  );
export default CovaloLogo;
