import axios, { AxiosError, AxiosResponse } from 'axios';
import { getUser } from 'auth/AuthenticationProvider';
import { captureMessage } from '@sentry/react';

export const axiosWithAuth = axios.create();

axiosWithAuth.interceptors.request.use(
  async config => {
    const token = getUser()?.access_token;
    if (token) {
      config.headers.set('authorization', `Bearer ${token}`);
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosWithAuth.interceptors.response.use(handleSuccess, handleError);

function handleSuccess(response: AxiosResponse) {
  return response;
}
function handleError(error: AxiosError) {
  const statusCode = error.response?.status || 0;
  const originalUrl = error.config?.url; // despite being types as non nullable, it can be actually undefined...
  const normalizedUrl = originalUrl
    ? new URL(originalUrl).pathname.replace(
        /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi,
        '[UUID]'
      )
    : 'unknown-url';

  // prevents grouping the same status code or the same URLs together
  const fingerprint = [`status-${statusCode}`, normalizedUrl];

  const tags = {
    errorCode: error.code,
    statusCode: statusCode,
    requestUrl: originalUrl
  };

  switch (statusCode) {
    case 0:
      // ignore the error,“network interrupted“ should not be logged, we have no impact on them and they are not interesting for us
      break;
    case 404:
      // ignore the error, 404 is not an error, it is an expected response
      break;
    case 409:
      // ignore the error, 409 is not an error, it is an expected response
      break;
    case 500:
      captureMessage(`Critical server error (500, ${normalizedUrl}`, {
        fingerprint,
        tags,
        level: 'error'
      });
      break;
    case 400:
      captureMessage(`Bad request error (400, ${normalizedUrl})`, {
        fingerprint,
        tags,
        level: 'error'
      });
      break;
    case 429:
      captureMessage('Rate limiting error (status 429)', {
        fingerprint,
        tags,
        level: 'info'
      });
      break;
    default:
      captureMessage(`Default error (status ${statusCode}, ${normalizedUrl})`, {
        fingerprint,
        tags,
        level: 'error'
      });
      break;
  }
  return Promise.reject(error);
}
