import { inviteUsers } from 'api/services/InviteService';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { FetchResponse, Option } from '../../types/global';
import { toast } from 'react-toastify';
import { useUserState } from 'context/User';
import Cancel from '@mui/icons-material/Cancel';
import Add from '@mui/icons-material/Add';
import { CompanySelectorMui } from 'components/common/CompanySelectorMui';
import { UserStateContextType } from 'components/constants/Constants';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { emailValidation } from 'helpers/ValidationPatterns';

interface InviteModalProps {
  show: boolean;
  onHide: Function;
}

export interface InviteUserInterface {
  users: {
    firstname: string;
    lastname: string;
    email: string;
    editor: boolean;
  }[];
}

export const InviteModal = ({ show, onHide }: InviteModalProps) => {
  const [company, setCompany] = useState<{ key: string; name: string }>();

  const userState = useUserState();
  const user =
    userState.type === UserStateContextType.AUTHENTICATED
      ? userState.user
      : null;
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isDirty, errors }
  } = useForm({
    defaultValues: {
      users: [
        {
          firstname: '',
          lastname: '',
          email: '',
          editor: false
        }
      ]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users'
  });

  useEffect(() => {
    user?.assignedCompanies?.[0] && setCompany(user.assignedCompanies[0]);
  }, [user]);

  const handleCloseModal = () => {
    onHide();
    reset();
  };
  const handleSave = (values: InviteUserInterface) => {
    // before we invite we need to check if the user is inviting to one of his companies or external company
    // if he invited to another comapny we use diferent endpoint

    // const isUserCompany =
    //   user?.assignedCompanies &&
    //   user?.assignedCompanies.some(company => company.key === companyKey);

    company &&
      // && isUserCompany
      // ?
      inviteUsers(
        company.key,
        values,
        handleCloseModal,
        (error: FetchResponse) => {
          if (error?.response?.data) {
            const { data: errorData } = error?.response || undefined;
            if (
              errorData?.status === 400 &&
              errorData?.additional_data?.failures
            )
              errorData?.additional_data?.failures?.forEach(
                (failure: string) => {
                  failure.includes('already exists')
                    ? toast.error(
                        failure.replace('User', 'An account with email ')
                      )
                    : failure !== ''
                    ? toast.error(failure)
                    : //when failure is empty
                      toast.error('Something went wrong');
                }
              );

            //when error status is not 400 or we dont have failue object
          } else toast.error('Something went wrong');
        }
      );
  };
  const multipleFieldsMode = fields.length > 1;

  return (
    <Dialog open={show} onClose={() => onHide()} maxWidth="md" fullWidth>
      <DialogTitle>Invite your colleagues</DialogTitle>
      <DialogContent>
        <CompanySelectorMui
          callInvitable
          onSelect={(option: Option) =>
            setCompany({
              key: option.value,
              name: option.label
            })
          }
          value={
            company?.key
              ? {
                  value: company?.key,
                  label: company?.name
                }
              : null
          }
        />
        <form id="inviteForm">
          {fields.map((item, index) => {
            return (
              <Grid
                container
                key={item.id}
                sx={theme => ({
                  border: `1px solid ${theme.palette.divider}`,
                  boxShadow: theme.shadows[1],
                  borderRadius: 2,
                  p: 2,
                  my: 2
                })}
              >
                <Grid container size={multipleFieldsMode ? 11 : 12} spacing={1}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      required
                      label="First name"
                      placeholder="First name"
                      {...register(`users.${index}.firstname`, {
                        required: true
                      })}
                      error={!!errors.users?.[index]?.firstname}
                      helperText={
                        errors.users?.[index]?.firstname &&
                        'Please enter a first name.'
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      required
                      label="Last name"
                      placeholder="Last name"
                      {...register(`users.${index}.lastname`, {
                        required: true
                      })}
                      error={!!errors.users?.[index]?.lastname}
                      helperText={
                        errors.users?.[index]?.lastname &&
                        'Please enter a last name.'
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <TextField
                      fullWidth
                      required
                      type="email"
                      label="Email"
                      placeholder="example@example.com"
                      {...register(`users.${index}.email`, {
                        required: true,
                        pattern: {
                          value: emailValidation,
                          message: 'Please enter a valid email address.'
                        }
                      })}
                      error={!!errors.users?.[index]?.email}
                      helperText={
                        errors.users?.[index]?.email &&
                        (errors.users?.[index]?.email?.message ||
                          'Please enter an email.')
                      }
                    />
                  </Grid>
                  <Grid size={12}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`users.${index}.editor`}
                            {...register(`users.${index}.editor`)}
                          />
                        }
                        label={
                          <Typography
                            sx={{ fontWeight: 'normal' }}
                            variant="subtitle2"
                            color="textSecondary"
                          >
                            Select Editor permissions if you would like your
                            colleague to help with reviewing or amending your
                            product data.
                          </Typography>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {multipleFieldsMode && (
                  <Grid
                    size={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <IconButton onClick={() => remove(index)} color="error">
                      <Cancel />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            );
          })}
        </form>
        <Button
          variant="outlined"
          sx={{ mt: 2 }}
          startIcon={<Add />}
          onClick={() => {
            append({
              firstname: '',
              lastname: '',
              email: '',
              editor: false
            });
          }}
        >
          User
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button
          variant="contained"
          type="submit"
          form="inviteForm"
          onClick={handleSubmit(handleSave)}
          disabled={!company?.key || !isDirty}
        >
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};
