import { InviteUserInterface } from 'components/NavigationBar/InviteModal';
import { request } from '../api';
import { baseApi } from 'components/constants/Constants';

export const inviteUsers = (
  companyKey: string,
  data: InviteUserInterface,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${companyKey}/users/invite`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  const isMultiple = data?.users?.length > 1;
  const toastOptions = {
    pending: `Sending invite${isMultiple ? 's' : ''}`,
    success: `Invite${isMultiple ? 's' : ''} sent`
  };
  request(options, handleSuccess, handleError, toastOptions, true);
};
