import { CompanyTypes } from 'api/types/ReferenceDataTS';
import { Permissions } from 'components/common/User/Permissions';

export interface EventUser {
  user: {
    firstname: string;
    lastname: string;
    email: string;
    country_name: string;
    key: string;
    department?: string;
  };
  company: {
    key: string;
    name: string;
    type: CompanyTypes;
  };
  status?: UserStatus;
  is_email_validated: boolean;
  is_internal_user: boolean;
  created_at: string;
  is_under_review: boolean;
  user_type: EventUserType;
  admin_notes?: string;
  permissions?: PortalPermissions;
}

export enum UserStatus {
  VERIFIED = 'VERIFIED',
  BLOCKED = 'BLOCKED'
}

export enum EventUserType {
  COMPETITOR = 'COMPETITOR',
  DIRECT_CUSTOMER = 'DIRECT_CUSTOMER',
  DISTRIBUTOR = 'DISTRIBUTOR',
  INDIRECT_CUSTOMER = 'INDIRECT_CUSTOMER',
  OTHER = 'OTHER'
}

export enum BrandPortalPermissionLevel {
  MANAGE = 'manage'
  // the permission will be undefined if the user should have no access
}
export enum PortalPermissionLevel {
  MANAGE = 'manage',
  VIEW = 'view'
  // the permission will be undefined if the user should have no access
}

export enum SupplierPortalPermissionKey {
  PRODUCT_MANAGEMENT = 'product.portal.supplier',
  USER_MANAGEMENT = 'user.portal.supplier',
  CONTENT_MANAGEMENT = 'content.portal.supplier'
}

export enum BrandPortalPermissionKey {
  PRODUCT_MANAGEMENT = 'product.portal.brand',
  USER_MANAGEMENT = 'user.portal.brand',
  COMPANY_MANAGEMENT = 'company.portal.brand',
  DOCUMENT_MANAGEMENT = 'document.portal.brand',
  SEARCH = 'search.portal.brand'
}

export type SupplierPortalPermissions = {
  [key in SupplierPortalPermissionKey]?: PortalPermissionLevel;
};

export type BrandPortalPermissions = {
  [key in BrandPortalPermissionKey]?: PortalPermissionLevel;
};

export type PortalPermissions = SupplierPortalPermissions &
  BrandPortalPermissions;

export type UserSettings = {
  event_user?: {
    status: 'VERIFIED';
  };
  permissions?: Permissions;
  notifications?: {
    [x: string]: boolean;
  };
};

export enum PortalTypes {
  BRAND_PORTAL = 'BRAND_PORTAL',
  SUPPLIER_PORTAL = 'SUPPLIER_PORTAL'
}

export interface ContentPost<T> {
  key: string;
  image: string;
  content: string;
  data: T;
  created_at: Date;
  created_by: string;
  modified_at: Date;
  modified_by: string;
  publish_date: string;
  status: ContentPostStatus;
}

/**
 * When changing this type, make sure to make the change backwards compatible.
 * There are many items in the database which still have the old type.
 */
export interface ContentPostData_MeetUs {
  booth: string;
  description: string;
  endDate: string;
  floorPlan: string;
  keyLaunches: string;
  keyLaunchesLink: string;
  location: string;
  spotlight: string;
  spotlightLink: string;
  startDate: string;
  title: string;
  eventName: string;
  // image: File | string;
  published: boolean;
}

/**
 * When changing this type, make sure to make the change backwards compatible.
 * There are many items in the database which still have the old type.
 */
export interface ContentPostData_PressRelease {
  title: string;
  description: string;
  downloadLink: string;
}

/**
 * When changing this type, make sure to make the change backwards compatible.
 * There are many items in the database which still have the old type.
 */

export interface ContentPostData_TrendsAndScience {
  title: string;
  description: string;
  category: TrendsAndScienceCategory;
  externalLink: string;
}

export enum TrendsAndScienceCategory {
  TRENDS = 'Trends',
  SCIENCE = 'Science'
}

export interface ContentPostData_Homepage {
  title: string;
  description: string;
  productLink: string;
}

export enum UXBContentCategory {
  HOMEPAGE = 'HOMEPAGE',
  MEET_US = 'MEET_US',
  PRESS_RELEASE = 'PRESS_RELEASE',
  SCIENCE_TRENDS = 'SCIENCE_TRENDS'
}

export enum ContentPostStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}
